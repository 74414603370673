exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-category-wp-category-slug-js": () => import("./../../../src/pages/category/{WpCategory.slug}.js" /* webpackChunkName: "component---src-pages-category-wp-category-slug-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-sitemap-js": () => import("./../../../src/pages/sitemap.js" /* webpackChunkName: "component---src-pages-sitemap-js" */),
  "component---src-pages-tags-wp-tag-slug-js": () => import("./../../../src/pages/tags/{WpTag.slug}.js" /* webpackChunkName: "component---src-pages-tags-wp-tag-slug-js" */),
  "component---src-pages-wp-post-slug-js": () => import("./../../../src/pages/{WpPost.slug}.js" /* webpackChunkName: "component---src-pages-wp-post-slug-js" */)
}

